import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"policy"} />
		<Helmet>
			<title>
				Summit Haven Ski Retreat
			</title>
			<meta name={"description"} content={"Yamaç Dinginliği, Dağ İhtişamı - Summit Haven'daki Maceranız Burada Başlıyor"} />
			<meta property={"og:title"} content={"Summit Haven Ski Retreat"} />
			<meta property={"og:description"} content={"Yamaç Dinginliği, Dağ İhtişamı - Summit Haven'daki Maceranız Burada Başlıyor"} />
			<meta property={"og:image"} content={"https://laxigenturra.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://laxigenturra.com/img/6347621.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://laxigenturra.com/img/6347621.png"} />
			<meta name={"msapplication-TileImage"} content={"https://laxigenturra.com/img/6347621.png"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section text-align="center" color="--dark" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Gizlilik Politikası
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Gizliliğiniz bizim için çok önemlidir. İnternet’teki çalışmalarınızın olabildiğince keyifli ve kullanışlı olmasını istiyoruz ve İnternet’in sunduğu en geniş bilgi, araç ve fırsatları tamamen sakin bir şekilde kullanıyorsunuz.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				( kaydı sırasında veya başka bir zamanda ) toplanan Üyelerin kişisel bilgileri esas olarak ihtiyaçlarınıza göre Ürün veya Hizmet hazırlamak için kullanılır. Bilgileriniz üçüncü taraflara aktarılmayacak veya satılmayacaktır. Bununla birlikte, “ Posta ile rıza ” ‘de açıklanan özel durumlarda kişisel bilgileri kısmen açıklayabiliriz
			</Text>
			<Text as="h2" font="--headline2" margin="40px 0 20px 0" text-align="left">
				Sitede hangi veriler toplanıyor
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Posta için gönüllü olarak kaydolduğunuzda, Adınızı ve E-postanızı kayıt formu aracılığıyla gönderirsiniz.
			</Text>
			<Text as="h2" font="--headline2" margin="40px 0 20px 0" text-align="left">
				Bu veriler hangi amaçla toplanıyor
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				İsim sizinle kişisel olarak iletişim kurmak için kullanılır ve e-postanız size bülten mektupları, haberler, faydalı materyaller, ticari teklifler göndermek için kullanılır.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Adınız ve e-postanız, yasal gerekliliklerin yerine getirilmesi ile ilgili durumlar dışında hiçbir koşulda üçüncü taraflara aktarılmaz.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Her harfteki abonelikten çıkma bağlantısını tıklayarak posta mektuplarını almayı reddedebilir ve iletişim bilgilerinizi veritabanından istediğiniz zaman silebilirsiniz.
			</Text>
			<Text as="h2" font="--headline2" margin="40px 0 20px 0" text-align="left">
				Bu veriler nasıl kullanılır
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Bu verileri kullanarak, içeriğini iyileştirmek, sitenin işlevselliğini geliştirmek ve sonuç olarak ziyaretçiler için yüksek kaliteli içerik ve hizmetler oluşturmak amacıyla ziyaretçilerin sitedeki eylemleri hakkında bilgi toplanır.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Tarayıcınızın tüm dosyaları engellemesi veya bu dosyaları göndermeniz konusunda sizi bilgilendirmesi için tarayıcınızın ayarlarını istediğiniz zaman değiştirebilirsiniz. Bazı işlevlerin ve hizmetlerin düzgün çalışamayacağını lütfen unutmayın.
			</Text>
			<Text as="h2" font="--headline2" margin="40px 0 20px 0" text-align="left">
				Bu veriler nasıl korunuyor
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Kişisel bilgilerinizi korumak için çeşitli idari, yönetsel ve teknik güvenlik önlemleri kullanıyoruz. Şirketimiz, internette toplanan bilgileri korumak için belirli kontrol önlemlerini içeren kişisel bilgilerle operasyonlara yönelik çeşitli uluslararası kontrol standartlarına uyar.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Çalışanlarımız bu kontrol önlemlerini anlamak ve uygulamak için eğitilmişlerdir, Gizlilik Bildirimimize, normlarımıza ve talimatlarımıza aşinadırlar.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Bununla birlikte, kişisel bilgilerinizi güvence altına almaya çalıştığımıza rağmen, onu korumak için de önlemler almalısınız.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				İnternette kalırken mümkün olan tüm önlemleri almanızı önemle tavsiye ederiz. Düzenlediğimiz hizmetler ve web siteleri, sızıntıya, yetkisiz kullanıma ve kontrol ettiğimiz bilgi değişikliğine karşı koruma önlemleri içerir. Ağımızın ve sistemlerimizin bütünlüğünü ve güvenliğini sağlamak için mümkün olan her şeyi yapmamıza rağmen, garanti edemeyiz, güvenlik önlemlerimizin üçüncü taraf kuruluşların bilgisayar korsanları tarafından bu bilgilere yasa dışı erişimin önlenmesine yardımcı olacağını.
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Bu gizlilik politikası değiştirilirse, bu değişiklikleri bu sayfada okuyabilir veya özel durumlarda e-postanız hakkında bir bildirim alabilirsiniz.
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});